import React from 'react';
import './ConsultingSection.css';

const ConsultingSection = () => {
  return (
    <div>
      <div class="section-heading"><h1>Consulting</h1></div>
      
      <div className={`consulting-section`}>
        <div className="column">
          <div class="image-container">
            <img src="https://firebasestorage.googleapis.com/v0/b/verdant-evolution-webpage.appspot.com/o/Robot.jpg?alt=media"
                alt="Description 1" />
          </div>
          <h2>Robotics Software</h2>
          <span>
            We provide advanced software for robotic platforms, enabling safe and
            complex decision making in dynamic environments.
            <br/><br/>
            We design high-quality user interfaces, making advanced robotic
            systems easy-to-use parts of your workflow.
          </span>
        </div>
        <div className="column">
          <div class="image-container">
            <img src="https://firebasestorage.googleapis.com/v0/b/verdant-evolution-webpage.appspot.com/o/Motherboard.jpg?alt=media"
                alt="Description 2" />
          </div>
          <h2>Data Processing</h2>
          <span>
            With our expertise in HPC, performance portable applications and data
            processing, we design technical solutions that scale to handle your
            largest work loads. and get the most out of your hardware.
          </span>
        </div>
        <div className="column">
          <div class="image-container">
            <img src="https://firebasestorage.googleapis.com/v0/b/verdant-evolution-webpage.appspot.com/o/Collaboration.jpg?alt=media"
                alt="Description 3" />
          </div>
          <h2>Support & Integration</h2>
          <span>
            We collaborate with you to create custom software that binds together
            your unique technologies into an intelligent system that is more than
            the sum of its parts. 

            We offer seminars, tailored education sessions, and technology
            guidance so you can confidently own your software long-term.
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConsultingSection;
