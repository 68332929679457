import React from 'react';
import Header from './components/Header';
import Background from './components/Background';
import ConsultingSection from './components/ConsultingSection';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div>
      <Header />
      <Background />
      <ConsultingSection />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
