import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <div className="header">
      <a href="." rel="noopener noreferrer" className="logo-link">
        <div className="logo">
          <img src="https://firebasestorage.googleapis.com/v0/b/verdant-evolution-webpage.appspot.com/o/Logo.svg?alt=media" alt="Logo" width="220px" />
        </div>
      </a>
    </div>
  );
};

export default Header;
