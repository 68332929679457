import React, { useState, useEffect } from 'react';
import './ContactForm.css';

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(email.length > 0 && message.length > 0);
  }, [email, message]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      try {
        const response = await fetch('https://email-server-vmj6t6xd3q-uc.a.run.app/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, message }),
        });

        if (response.ok) {
          console.log('Email sent successfully');
          setEmail('');
          setMessage('');
        } else {
          console.error('Failed to send email');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <div className="contact-form-container">
      <form onSubmit={handleSubmit}>
        <h2>Contact Us</h2>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            style={{resize: 'none'}}
          />
        </div>
        <button type="submit" disabled={!isFormValid}>Send Message</button>
      </form>
    </div>
  );
};

export default ContactForm;
