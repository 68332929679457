import React, { useEffect } from 'react';
import './Background.css';

const Background = () => {

  const handleScroll = () => {
    const background = document.querySelector('.background');
    const scrollPosition = window.pageYOffset;
    background.style.backgroundPositionY = `${scrollPosition * 0.5 - 110}px`;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="background">
      <h1>Verdant Evolution</h1>
      <span>Advanced Software for Advanced Applications</span>
    </div>
  );
};

export default Background;
