import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 Verdant Evolution. All rights reserved.</p>
        <p>Contact Us
          <br/>
          Email: contact@verdantevolution.com </p>
      </div>
    </footer>
  );
};

export default Footer;
